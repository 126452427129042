import { LayoutDefault } from '@features/layouts/layout-default';
import { getHeaderType } from '@features/layouts/layout-default/utils';
import { PageBuilder } from '@features/page-builder';
import { SEO } from '@features/seo';
import { TYPE_MARKDOWN_NODES, TYPE_STRAPI_PAGE } from '@infra/cms/types';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { mapContentToComponents } from '@utils/map-content-to-components';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';

const Template = props => {
  const {
    pageContext: { locale },
    location,
    data: { cms, md },
  } = props;

  const components = mapContentToComponents({
    content: get(md, 'nodes', []),
    components: cms.pageComponents,
  });

  const {
    metaTitle: title,
    metaDescription: description,
    metaIndex: index,
    metaFollow: follow,
    metaCanonicalURLOverride: canonical,
    layout: layoutType,
  } = cms.PageMeta;

  const headerType = getHeaderType({ layoutType });

  return (
    <LayoutDefault locale={locale} location={location} headerType={headerType}>
      <SEO
        location={location}
        title={title}
        description={description}
        isNoIndex={index === 'noindex'}
        isNoFollow={follow === 'nofollow'}
        canonicalURLOverride={canonical}
        lang={locale}
      />
      <PageBuilder components={components} />
    </LayoutDefault>
  );
};

const query = graphql`
  query($id: String!) {
    cms: strapiPage(id: { eq: $id }) {
      ...StrapiPageData
    }
    md: allMarkdownRemark(filter: { parent: { parent: { id: { eq: $id } } } }) {
      nodes {
        parent {
          ... on ExtractedMarkdown {
            fields {
              meta
            }
          }
        }
        html
      }
    }
  }
`;

Template.propTypes = {
  ...gatsbyPageProps,
  data: PropTypes.shape({
    cms: TYPE_STRAPI_PAGE,
    md: TYPE_MARKDOWN_NODES,
  }).isRequired,
};

export default Template;
export { query, Template };
